import React from 'react'

import Layout from '~components/layout'
import NotFound from '~components/not-found'

const NotFoundPage = props => {
  const { section, locale } = props.pageContext
  const { location } = props

  return (
    <Layout locale={locale} location={location} section={section}>
      <NotFound />
    </Layout>
  )
}

export default NotFoundPage
