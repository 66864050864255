import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import { useIntl, FormattedMessage } from 'react-intl'

import SEO from '~components/seo'

const NotFound = () => {
  const { locale, messages } = useIntl()
  const key = locale.toLowerCase()

  const data = useStaticQuery(staticQuery)
  const { settings } = data
  const { notFoundPageBackground } = settings

  return (
    <>
      <SEO lang={locale} title={messages['page-not-found-title']} />
      <div>
        <div className='relative'>
          <div className='absolute inset-0 z-0 w-full h-full overflow-hidden'>
            <Image
              fluid={notFoundPageBackground.fluid}
              className='w-full h-full'
              imgStyle={{ objectFit: 'cover', objectPosition: 'center' }}
              loading='eager'
              alt='N/A'
              aria-hidden
            />
          </div>
          <div className='absolute inset-0 z-10 lg:hidden w-full h-full bg-white-translucent overflow-hidden' />

          <div className='relative z-20 flex justify-center lg:justify-end max-w-page py-64'>
            <div className='max-w-lg'>
              <div className='font-extrabold text-6xl leading-tight uppercase'>
                <FormattedMessage id='page-not-found-body' />
              </div>
              <div className='text-xl'>
                <FormattedMessage id='page-not-found-cta' />
              </div>
              <div className='button button-blue mt-4'>
                <Link to={`/${key}/`}>
                  <FormattedMessage id='page-not-found-link' />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound

const staticQuery = graphql`
  {
    settings: contentfulSettings(title: { eq: "Global Settings" }) {
      notFoundPageBackground: pageBackground {
        fluid(maxWidth: 1440, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`
